/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./mode-banner.component";
var styles_ModeBannerComponent = [];
var RenderType_ModeBannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModeBannerComponent, data: {} });
export { RenderType_ModeBannerComponent as RenderType_ModeBannerComponent };
function View_ModeBannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "mode-banner alert archive-warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This is the "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["archived documentation for Angular v", "."])), (_l()(), i0.ɵted(-1, null, [" Please visit "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["href", "https://angular.io/"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["angular.io"])), (_l()(), i0.ɵted(-1, null, [" to see documentation for the current version of Angular."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.version == null) ? null : _co.version.major); _ck(_v, 4, 0, currVal_0); }); }
export function View_ModeBannerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModeBannerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode == "archive"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModeBannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-mode-banner", [], null, null, null, View_ModeBannerComponent_0, RenderType_ModeBannerComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModeBannerComponent, [], null, null)], null, null); }
var ModeBannerComponentNgFactory = i0.ɵccf("aio-mode-banner", i2.ModeBannerComponent, View_ModeBannerComponent_Host_0, { mode: "mode", version: "version" }, {}, []);
export { ModeBannerComponentNgFactory as ModeBannerComponentNgFactory };
