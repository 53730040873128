/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./nav-item.component";
var styles_NavItemComponent = [];
var RenderType_NavItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavItemComponent, data: {} });
export { RenderType_NavItemComponent as RenderType_NavItemComponent };
function View_NavItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "vertical-menu-item"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "vertical-menu-item"; var currVal_3 = _co.classes; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.node.url, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.node.tooltip, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.node.title; _ck(_v, 5, 0, currVal_4); }); }
function View_NavItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "vertical-menu-item heading"]], [[8, "href", 4], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.headerClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "rotating-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard_arrow_right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i3.MatIcon, [i0.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "vertical-menu-item heading"; var currVal_3 = _co.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_7 = "keyboard_arrow_right"; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.node.url, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.node.tooltip, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.node.title; _ck(_v, 4, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 6).inline; var currVal_6 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_5, currVal_6); }); }
function View_NavItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["class", "vertical-menu-item heading"], ["type", "button"]], [[8, "title", 0], [1, "aria-pressed", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.headerClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "rotating-icon mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard_arrow_right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i3.MatIcon, [i0.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "vertical-menu-item heading"; var currVal_3 = _co.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_7 = "keyboard_arrow_right"; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.node.tooltip, ""); var currVal_1 = _co.isExpanded; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.node.title; _ck(_v, 4, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 6).inline; var currVal_6 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_5, currVal_6); }); }
function View_NavItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-nav-item", [], null, null, null, View_NavItemComponent_0, RenderType_NavItemComponent)), i0.ɵdid(1, 573440, null, 0, i4.NavItemComponent, [], { isWide: [0, "isWide"], level: [1, "level"], node: [2, "node"], isParentExpanded: [3, "isParentExpanded"], selectedNodes: [4, "selectedNodes"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isWide; var currVal_1 = (_co.level + 1); var currVal_2 = _v.context.$implicit; var currVal_3 = _co.isExpanded; var currVal_4 = _co.selectedNodes; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_NavItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavItemComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavItemComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "heading-children"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavItemComponent_5)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.node.url != null); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.node.url == null); _ck(_v, 4, 0, currVal_1); var currVal_2 = "heading-children"; var currVal_3 = _co.classes; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.nodeChildren; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_NavItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavItemComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.node.children; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.node.children; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NavItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-nav-item", [], null, null, null, View_NavItemComponent_0, RenderType_NavItemComponent)), i0.ɵdid(1, 573440, null, 0, i4.NavItemComponent, [], null, null)], null, null); }
var NavItemComponentNgFactory = i0.ɵccf("aio-nav-item", i4.NavItemComponent, View_NavItemComponent_Host_0, { isWide: "isWide", level: "level", node: "node", isParentExpanded: "isParentExpanded", selectedNodes: "selectedNodes" }, {}, []);
export { NavItemComponentNgFactory as NavItemComponentNgFactory };
