/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./select.component";
var styles_SelectComponent = [];
var RenderType_SelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectComponent, data: {} });
export { RenderType_SelectComponent as RenderType_SelectComponent };
function View_SelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "symbol ", ((_co.selected == null) ? null : _co.selected.value), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SelectComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "symbol ", _v.parent.context.$implicit.value, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["role", "button"], ["tabindex", "0"]], [[2, "selected", null]], [[null, "click"], [null, "keydown.enter"], [null, "keydown.space"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.select(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.space" === en)) {
        _co.select(_v.context.$implicit, _v.context.index);
        var pd_2 = ($event.preventDefault() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showSymbol; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.selected); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
function View_SelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "form-select-dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "form-select-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "button", [["class", "form-select-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectComponent_2)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showSymbol; _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.showOptions; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 4, 0, currVal_1); var currVal_3 = ((_co.selected == null) ? null : _co.selected.title); _ck(_v, 8, 0, currVal_3); }); }
export function View_SelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-select", [], null, [["document", "click"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onKeyDown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_SelectComponent_0, RenderType_SelectComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectComponentNgFactory = i0.ɵccf("aio-select", i2.SelectComponent, View_SelectComponent_Host_0, { selected: "selected", options: "options", showSymbol: "showSymbol", label: "label", disabled: "disabled" }, { change: "change" }, []);
export { SelectComponentNgFactory as SelectComponentNgFactory };
